<template>
    <BlockContainer
        v-editable="blok"
        :background-color="blok.background_color"
        :bottom-angle="blok.bottom_angle"
        :angle-background-color="nextBackgroundColor"
        :id="'process-select-' + slugify(processSelectData.selectionHeading ?? '')"
    >
        <ProcessSelect v-editable="blok" :data="processSelectData" />
    </BlockContainer>
</template>

<script setup lang="ts">
import ImageType from '~/types/ImageType';
import ProcessSelectType from '~/types/ProcessSelectType';
import ProcessType from '~/types/ProcessType';
import ProcessStepType from '~/types/ProcessStepType';
import ProcessSelect from '~/components/page-building/process-select/ProcessSelect.vue';
import LinkType from '~/types/LinkType';
import ButtonType from '~/types/ButtonType';
import ImageContentType from '~/types/ImageContentType';
import VideoContentType from '~/types/VideoContentType';
import BlockContainer from '~/components/BlockContainer.vue';
import CtaCardType from '~/types/CtaCardType';

const props = defineProps({
    blok: {
        type: Object,
        required: true,
    },
    nextBlock: {
        type: Object,
        required: false,
        default: null,
    },
});

const processSelectData = computed<ProcessSelectType>(() => {
    const processes = props.blok.processes
        ? props.blok.processes.map((process: any) => {
              const processSteps = process.process_steps
                  ? process.process_steps.map((step: any) => {
                        return {
                            image: {
                                title: step.image.title,
                                alt: step.image.alt,
                                src: step.image.filename,
                            } as ImageType,
                            title: step.title,
                            subtitle: step.subtitle,
                            description: richTextToHtml(
                                step.description,
                            ) as string,
                            link:
                                step.link && step.link.length > 0
                                    ? ({
                                          label: step.link[0].label,
                                          url:
                                              '/' +
                                              step.link[0].target.cached_url,
                                      } as LinkType)
                                    : null,
                            inlineImageContent: step.inline_content
                                ? step.inline_content
                                      .filter(function (content: any) {
                                          if (
                                              content.component ==
                                              'image_content'
                                          ) {
                                              return true;
                                          }
                                          return false;
                                      })
                                      .map((content: any) => {
                                          return {
                                              image: {
                                                  title: content.image.title,
                                                  alt: content.image.alt,
                                                  src: content.image.filename,
                                              } as ImageType,
                                              heading: content.heading,
                                              description: richTextToHtml(
                                                  content.description,
                                              ) as string,
                                              buttons: content.buttons
                                                  ? content.buttons.map(
                                                        (button: any) => {
                                                            return {
                                                                label: button.label,
                                                                style: button.style,
                                                                url: button.link
                                                                    .cached_url,
                                                                vEditable:
                                                                    button,
                                                            } as ButtonType;
                                                        },
                                                    )
                                                  : [],
                                              reverseLayout:
                                                  content.reverse_layout,
                                              backgroundColor:
                                                  content.background_color,
                                              nextBackgroundColor: null,
                                              arrowCallout:
                                                  content.arrow_callout_type
                                                      ? {
                                                            type: content.arrow_callout_type,
                                                            text: richTextToHtml(
                                                                content.arrow_callout_text,
                                                            ) as string,
                                                        }
                                                      : null,
                                              bottomAngle: content.bottom_angle,
                                          } as ImageContentType;
                                      })
                                : [],
                            inlineVideoContent: step.inline_content
                                ? step.inline_content
                                      .filter(function (content: any) {
                                          if (
                                              content.component ==
                                              'video_content'
                                          ) {
                                              return true;
                                          }
                                          return false;
                                      })
                                      .map((content: any) => {
                                          return {
                                              videoUrl: content.video_url,
                                              heading: content.heading,
                                              content: richTextToHtml(
                                                  content.content,
                                              ) as string,
                                              buttons: content.buttons
                                                  ? content.buttons.map(
                                                        (button: any) => {
                                                            return {
                                                                label: button.label,
                                                                style: button.style,
                                                                url: button.link
                                                                    .cached_url,
                                                                vEditable:
                                                                    button,
                                                            } as ButtonType;
                                                        },
                                                    )
                                                  : [],
                                              reverseLayout:
                                                  content.reverse_layout,
                                              backgroundColor:
                                                  content.background_color,
                                              nextBackgroundColor: null,
                                              arrowCallout:
                                                  content.arrow_callout_type
                                                      ? {
                                                            type: content.arrow_callout_type,
                                                            text: richTextToHtml(
                                                                content.arrow_callout_text,
                                                            ) as string,
                                                        }
                                                      : null,
                                              bottomAngle: content.bottom_angle,
                                          } as VideoContentType;
                                      })
                                : [],
                            vEditable: step.link.cached_url,
                        } as ProcessStepType;
                    })
                  : [];

              return {
                  selectImage: process.select_image
                      ? ({
                            title: process.select_image.title,
                            alt: process.select_image.alt,
                            src: process.select_image.filename,
                        } as ImageType)
                      : null,
                  selectLabel: process.select_label,
                  slug: slugify(process.select_label),
                  heading: process.heading,
                  processSteps: processSteps,
                  display: false,
                  vEditable: process,
              } as ProcessType;
          })
        : [];
    return {
        selectionHeading: props.blok.selection_heading,
        processes,
        cta:
            props.blok.cta && props.blok.cta[0]
                ? ({
                      heading: props.blok.cta[0].heading,
                      text: richTextToHtml(props.blok.cta[0].text) as string,
                      link: {
                          label: props.blok.cta[0].link_label,
                          url: props.blok.cta[0].link.cached_url,
                          vEditable: props.blok.cta[0].link,
                      } as LinkType,
                  } as CtaCardType)
                : null,
    } as ProcessSelectType;
});

const nextBackgroundColor = computed<string | null>(() => {
    return props.nextBlock ? props.nextBlock.background_color : null;
});
</script>
